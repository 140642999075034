@import "../../styles/_variables.scss";

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $bg-color;
  .pageContent {
    flex: 1;
    overflow-y: auto;
  }
}