@import "../../styles/_variables.scss";

.container {
  // white-space: nowrap;
  position: relative;
  label {
    display: inline-block;
    font-family: 'MuseoSans';
    font-size: $regular;
    color: $text-color;
  } 
}