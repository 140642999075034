@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.create {
  min-height: 100%;
  @media screen and (min-width: $lg-screen) {
    background-image: url('../../../assets/images/users-permanents.png');
    background-repeat: no-repeat;
    background-position: right;
  }
}