@import "../../../styles/_variables.scss";

.dropInputFile {
  width: 100%;
  height: 100%;
  min-height: 340px;
  min-width: 340px;

  @media screen and (min-width: 490px) {
    min-height: 200px;
    min-width: 400px;
  }

  .actions {
    display: flex;
    margin-top: $margin-sm;
    button {
      margin-left: auto;
      background-color: $white;
      color: $secondary-color;
      padding: $margin-sm;
    }
  }

}

.dropInputFile .container {
  width: 100%;
  height: 100%;
  min-height: 340px;
  min-width: 340px;
  text-align: center;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  

  @media screen and (min-width: 490px) {
    min-height: 200px;
    min-width: 400px;
  }

  &.withPreview label {
    button, p {
      opacity: 0;
    } 
  }

  input {
    display: none;
  }

  label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-style: dashed;
    border-color: $grey;
    background-color: $bg-color;
    cursor: pointer;
    
    p, button {
      color: $secondary-color;
    }

    &.dragActive {
      border-color: $black;
    }    
  }

  .dragFile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  label button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
    &:hover {
      text-decoration-line: underline;
    }
  }

  img {
    position: absolute;
    z-index: 0;
    pointer-events: none;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .delete {
    position: absolute;
    z-index: 1;
    right: $margin-sm;
    bottom: $margin-sm
  }

}




