@import "../../../styles/_variables.scss";
@import "../_form-mixin.scss";

.checkbox {
  @include remove-highlight();
  &.inline {
    display: flex;
    align-items: center;
    .container {
      margin-right: $margin-xs;
    }

    label {
      margin: 0 0 0 $margin-xs;
    }

  }
  label {
    @include label;
    cursor: pointer;
  }

  .container {
    display: flex;
    justify-content: flex-start;
    height: 20px;
  }

  .square {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    >svg {
      position: absolute;
      width: 20px;
      height: 20px;
    }
  }

  &.secondary {
    .square {
      background-color: $secondary-color;
      border: solid 3px $secondary-color;
      >svg {
        color: $white;
      }
    }
    &.notchecked {
      label {
        color: $text-color-light;
        opacity: 0.5;
      }
      .square {
        background-color: $white;
        border: solid 3px $border-grey;
      }
    }
    &.disabled {
      label {
        pointer-events: none;
        cursor: none;
      }
      .square {
        background-color: $border-grey;
        border: solid 3px $border-grey;
        pointer-events: none;
        cursor: none;
      }
    }
  }
  

  &:not(.secondary) .square {
    background-color: $white;
    border: solid 3px $text-color;
    >svg {
      color: $text-color;
    }
  }

}