@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.timeslots {
  display: flex;
  margin: $margin-md 0 0 0;

  label {
    display: block;
    font-family: "Museo-Bold", sans-serif;
    font-weight: normal;
    font-size: $regular;
    line-height: 17px; 
    color: $text-color;
    white-space: nowrap;
  }

  .selectSlot {
    position: relative;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
      pointer-events: none;
      p {
        opacity: 0.5;
        cursor: not-allowed !important;
        color: $text-color-light;
      }
    }
    .timeslot {
      display: flex;
      background-color: $white;
      padding: $margin-xs;
      border-radius: 3px;
      width: 120px;
      margin: 0 $margin-sm $margin-sm 0;

      &:hover p:first-child {
        background-color: $secondary-color;
        color: $white;
      }

      p {
        padding: $margin-xs;
        border-radius: 3px;
        margin: 0;
        cursor: pointer;
        transition: all .3s ease-in-out;
      }
    }
    .dropdown {
      position: absolute;
      z-index: 2;
      display: flex;
      flex-direction: column;
      width: 200px;
      overflow: hidden;
      border-radius: 5px;
      background-color: $white;
      box-shadow: 0px 0px 26.438px 0px rgba(2, 53, 67, 0.15);
      // transform: translateY(10px);
      .options {
        flex: 1;
        width: 100%;
        overflow: auto;
        padding: 0 15px;
        max-height: calc(29px * 5);
        p {
          cursor: pointer;
          margin: 0;
          padding: 5px 0;
        }
      }

      .delete {
        padding: 5px 0;
        border-top: solid 1px $grey;
        p {
          color: $warn;
          font-family: 'MuseoSans-Bold';
          text-align: center;
          margin: 0;
          cursor: pointer;
        }
      }

    }
  }

  // ul {
  //   display: inline-block;
  //   padding: 0;
  //   margin: $margin-xs 0 0 0;
  //   background-color: $white;
  //   box-shadow: inset 0 0 0 1px $grey;
  //   border-radius: 5px;
  //   li {
  //     display: inline-block;
  //     list-style: none;

  //     &:first-child div {
  //       border-radius: 5px 0 0 5px;
  //     }

  //     &:last-child div {
  //       border-radius: 0 5px 5px 0;
  //     }

  //     div {
  //       background-color: $white;
  //       padding: $margin-sm $margin-sm;
  //       box-shadow: inset 0 0 0 1px $grey;
  //       margin-left: -1px;
  //       cursor: pointer;
  //     }

  //     &.active div {
  //       border-radius: 5px;
  //       background-color: $secondary-color;
  //       color: $white;
  //       box-shadow: none;
  //     }

  //   }
  // }

}