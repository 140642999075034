@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.magasins {
  @media screen and (min-width: 1180px) {
    height: 100%;
  }
  .content {
    @include content();
    display: flex;
    flex-direction: column;
    height: 100%;  
  }
}