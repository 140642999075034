@import "../../../styles/_variables.scss";

.overlay-modal{
  position: fixed;
  z-index: 3000;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba(26, 27, 66, .6);
}

.modal{
  position: fixed;
  display: flex;
  flex-direction: column;

  z-index: 3001;
  top:0;
  left:50%;
  transform: translate(-50%, 0%);
  width: 100%;
  height: 100%;
  max-width: 310px;
  border-radius: 8px;
  background-color: white;
  // overflow-y: auto;
  // overflow-x: hidden;

  @media screen and (min-width: $md-screen) {
    top:50%;
    height: auto;
    transform: translate(-50%, -50%);
    width: calc(100% - $margin-xl);
    max-height: calc(100% - $margin-xl);
  }

  .content {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @media screen and (min-width: $md-screen) {
      padding: 20px;
    }
  }

  .close {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    background-color: $secondary-color;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 0 0 0 40px;
    cursor: pointer;

    @media screen and (max-width: $md-screen) {
      svg {
        position: relative;
        transform: translate(3px, -5px) scale(1.3);
      }
    }


    @media screen and (min-width: $md-screen) {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%,-50%);
      width: 36px;
      height: 36px;
      border-radius: 18px;
    }

  }

  p{
    margin-top: 0;
  }

  .container-btn{
    display: flex;
    justify-content: center;
    button:first-child{
      margin-right: 5px;
    }
    button:last-child{
      margin-left: 5px;
    }
  }
}
