@import '../../../styles/variables';
@import '../../../styles/mixins';

.presences {
  max-width: 600px;
  margin-bottom: $margin-xl;

  &.register {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $margin-md;

    @media screen and (min-width: $sm-screen) and (max-width: $md-screen) {
      grid-template-columns: 1fr;
      // max-width: 100%;
      // >div:nth-child(2) label {
      //   display: none;
      // }
    }

    @media screen and (min-width: $md-screen) and (max-width: $lg-screen) {
      grid-template-columns: 1fr;
    }

    @media screen and (min-width: $lg-screen) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      max-width: 100%;
      // >div:nth-child(2) label {
      //   display: none;
      // }
    }

    .logo {
      display: flex;
      align-items: center;
      border: solid 2px $grey;
      padding: $margin-sm;

      @media screen and (min-width: $lg-screen) {
        grid-area: 2 / 2 / 3 / 3;
        align-self: start;
      }
  
      img {
        width: 100%;
        max-width: 200px;
        object-fit: contain;
          
        @media screen and (min-width: $lg-screen) {
          max-width: 100px;
        }
      }
  
      .hr {
        width: 3px;
        height: 100px;
        background-color: $grey;
        border: none;
        justify-self: center;
        margin: 0 $margin-md;
      }

      >div {
        flex: 1;
        p {
          text-transform: uppercase;
          color: $secondary-color;
          font-family: 'Museo-ExtaBold';
        }
    
        span {
          font-family: 'Museo-Bold';
        }
    
      }

    }

  }

  &.account {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $margin-md;


    @media screen and (min-width: 800px) {
      grid-template-columns: 1fr 1fr;
      >div:nth-child(2) label {
        display: none;
      }
    }

  }

  // .visible {
  //   grid-area: 2 / 1 / 3 / 2;
  // }

  .disabled {
    opacity: 0.3;
    pointer-events: none;
    // grid-area: 2 / 1 / 3 / 2;
  }

  >div>p {
    font-family: 'Museo-Bold';
    font-size: $regular;
    margin-bottom: $margin-md;
  }

}
