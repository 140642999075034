@import "../../styles/_variables.scss";

.inputImage {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 120px;
  min-width: 120px;
  .actions {
    display: flex;
    margin-top: $margin-sm;
    button {
      margin-left: auto;
      background-color: $white;
      color: $secondary-color;
      padding: $margin-sm;
    }
  }

  .error {
    position: absolute;
    bottom: 2px;
    color: $warn;
    font-size: 12px;
    margin: 0;
    min-width: 120px;
    text-align: center;
  }

}

.inputImage .container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 120px;
  min-width: 120px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border: solid 1px $secondary-color;
  border-radius: 5px;
  background-color: $white;
  background-color: $bg-color;

  &.withPreview label {
    button, p {
      opacity: 0;
    } 
  }

  input {
    display: none;
  }

  label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $secondary-color;
    overflow: hidden;
    
    svg {
      opacity: 0.2;
    } 

    &.dragActive {
      border-color: $black;
    }    
  }

  .dragFile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  label button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
    &:hover {
      text-decoration-line: underline;
    }
  }

  img {
    position: absolute;
    z-index: 0;
    pointer-events: none;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .delete {
    position: absolute;
    z-index: 1;
    right: $margin-xs;
    bottom: $margin-xs;
    color: $white;
    cursor: pointer;
  }

}




