@import "../../styles/_variables.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border: solid 1px $secondary-color;
  padding: 0;
  height: 45px;
  width: 45px;
}