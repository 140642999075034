@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.texteditor {

  label {
    @include label;
    color: $text-color;
    + div {
      margin-top: 10px;
    }
  }

  .wrapper {
    border: solid 1px $border-grey;
    border-radius: $radius;
    // overflow: hidden;
    background: $white;
  }

  .toolbar {
    background-color: #FAFAFA;
    border-radius: 4px 4px 0 0;
    margin-bottom: 0;
  }

  .editor {
    border-radius: 0 0 4px 4px;

    div[class$="-DraftEditor-content"] {
      padding: 5px $margin-xs 0 $margin-xs;
    }

    &.heightAuto {
      height: calc(100% - 44px);
    }

    &:not(.heightAuto) {
      div[class$="-DraftEditor-content"] {
        overflow: auto;
        height: 170px;
        max-height: 170px;
      }
    }

    figure {
      margin: 0;
      span[class*="-image-alignment"] {
        justify-content: flex-start !important;
      }
    }
    

  }



}