@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.inputDuration {
  label {
    display: block;
    font-family: "Museo-Bold", sans-serif;
    font-weight: normal;
    font-size: $regular;
    line-height: 17px; 
    color: $text-color;
    white-space: nowrap;
  }

  ul {
    display: inline-block;
    padding: 0;
    margin: $margin-xs 0 0 0;

    @media screen and (min-width: 600px) {
      background-color: $white;
      border-radius: 5px;
      box-shadow: inset 0 0 0 1px $grey;
    }

    li {
      display: inline-block;
      list-style: none;

      div {
        background-color: $white;
        padding: $margin-sm $margin-sm;
        box-shadow: inset 0 0 0 1px $grey;
        margin: 0 $margin-xs $margin-xs 0;
        border-radius: 5px;
        cursor: pointer;
      }

      @media screen and (min-width: 600px) {
        div {
          margin: 0 0 0 -1px;
        }

        &:first-child div {
          border-radius: 5px 0 0 5px;
        }
  
        &:last-child div {
          border-radius: 0 5px 5px 0;
        }
  
      }

      &.active div {
        border-radius: 5px;
        background-color: $secondary-color;
        color: $white;
        box-shadow: none;
      }

    }
  }

}