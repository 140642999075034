@import '../../styles/mixins';
@import '../../styles/variables';


.presenceValidated {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin-bottom: $margin-lg;
    &.error {
      color: #EC5E52;
    }
  }
}

.reception {

  .content {
    @include content();
    display: flex;
    flex-direction: column;
    max-width: 440px;
    width: 100%;
    padding: $margin-sm;
    margin: 0 auto;
  
    @media screen and (min-width: $xs-screen) {
      padding: $margin-md;
    }
  
    @media screen and (min-width: $sm-screen) {
      padding: $margin-lg;
    }

    h1 {
      font-size: 28px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: $margin-md;
      margin-bottom: $margin-md;
      max-width: 400px;
      height: 75px;

      &.formations {
        color: $secondary-color;
        background-color: $white;
      }

      svg {
        margin-right: $margin-sm
      }
    }

  }


}