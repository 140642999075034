@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.permanents {
  height: 100%;

  .content {
    @include content();
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}