@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background-color: $white;
  border-radius: $radius;
  overflow-y: auto;
  overflow-x: hidden;
}