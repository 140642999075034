@import '../../../styles/_variables.scss';

.magasinsTable {
  padding: 0 $margin-lg 0 $margin-lg;
  background-color: $white;
  border-radius: $radius;
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    border-spacing: 0;

    thead {
      position: relative;
      position: sticky;
      top: 0;
      background-color: white;
      &:after {
        content: '';
        position: absolute;
        right: calc(-1 * $margin-lg);
        left: calc(-1 * $margin-lg);
        bottom: 0;
        border-bottom: solid 1px $border-grey;
      }


      th {
        position: relative;
        padding: $margin-md 0 $margin-md 0;
        font-family: 'Museo-light';
        color: $text-color-light;
        text-align: left;
        cursor: pointer;

        &:nth-child(1) {
          width: 160px;
          @media screen and (min-width: $md-screen) {
            width: 200px;
          }
        }

        &:nth-child(2) {
          width: 160px;
          @media screen and (min-width: $md-screen) {
            width: 200px;
          }
        }

        &:last-child {
          width: 160px;
        }

        .sortIcons {
          position: relative;
          top: -6px;
          display: inline-flex;
          flex-direction: column;
          // opacity: 0.3;
          margin-left: $margin-xs;
          height: 20px;

          span:not(.active) {
            opacity: 0.3;
          }

          >span:first-child {
            transform: translateY(5px);
          }
          >span:nth-child(2) {
            transform: translateY(-5px);
          }
        }

      }
    }

    tbody {
      tr {
        cursor: pointer;
        &:not(:last-child) td {
          border-bottom: solid 1px $border-grey;
        }
      }
      
      td {
        padding: $margin-md $margin-xs $margin-md 0;
        width: 20%;

        &.name p{
          font-family: 'MuseoSans-Bold';
        }
    
        p {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

      }
    }

  }
}