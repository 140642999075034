@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.edit {
  min-height: 100%;
  @media screen and (min-width: $lg-screen) {
    background-image: url('../../../assets/images/users-permanents.png');
    background-repeat: no-repeat;
    background-position: right;
  }

  .delete {
    color: $warn;
    margin-top: $margin-md;
    @media screen and (min-width: $md-screen) {
      margin-top: 0;
    }

    svg {
      position: relative;
      top: 2px;
      margin-right: $margin-xs;
    }
  }

}