@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.users {
  .content {
    @include content();
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .delete {
    color: $warn;
    margin-top: $margin-md;
    @media screen and (min-width: $md-screen) {
      margin-top: 0;
    }

    svg {
      position: relative;
      top: 2px;
      margin-right: $margin-xs;
    }
  }
  
}