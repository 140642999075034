@import '../../../styles/mixins';
@import '../../../styles/variables';

.detailTimeslot {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  .content {
    flex: 1;

    overflow-y: auto;
    @include content();

    .header {
      margin-bottom: $margin-md;

      h3 {
        font-family: 'MuseoSans-Bold';
        margin-top: $margin-md;
        span {
          font-family: 'Museo-Light';
        }
      }

      p {
        margin-top: $margin-xs;
        color: $secondary-color;
      }

    }

    .users {
      overflow-y: auto;
      padding: 0 $margin-xs;
      .user {
        @media screen and (min-width: 360px) {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }

        &:not(:last-child) {
          padding-bottom: $margin-sm;
          margin-bottom: $margin-sm;
          border-bottom: solid 1px $grey;
        }
  

        p {
          margin: 0 0 $margin-xs 0;
        }

        .detail {

          .name {
            font-family: 'MuseoSans-Bold';
            font-size: $regular;
          }
  
          p {
            font-family: 'Museo-Light';
            white-space: nowrap;

            &:not(.name) {
              max-width: 230px;
              text-overflow: ellipsis;
              overflow: hidden;
            }
  
            span {
              color: $text-color-light;
            }
          }
        }
  
        .presence {
          &.validated {
            color: #008767;
          }
          &:not(.validated) {
            opacity: 0.2;
            svg {
              position: relative;
              top: 3px;
            }
          }
          svg {
            position: relative;
            top: 2px;
            margin-left: 3px;
          }
        }
  
  
      }
    }

  }

  >button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    padding: $margin-lg $margin-md;
    span {
      margin: 0 7px;
    }
  }


}