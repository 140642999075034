
@import './_variables.scss';

@mixin page-auth {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  // height: 100%;
  min-height: 100%;
  background: $gradient;
  padding: $margin-md 0 0 0;

  @media screen and (min-width: $xs-screen) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: $margin-md $margin-sm;
  }

  @media screen and (min-width: $md-screen) {
    padding: 0 $margin-sm;
  }

  .logo {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    

    @media screen and (min-width: $md-screen) {
      display: block;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 0;
      left: -100px;
      top: -160px; 
      display: block;
      width: 383px;
      height: 375px;
      border-radius: 50%;
      background-color: $white;
    }

    img {
      position: relative;
      z-index: 1;
      margin: 35px;
      width: 125px;
      
    }

  }

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: $xs-screen) {
      flex: inherit;
    }

    >div:first-child {
      text-align: center;
    
      h1 {
        font-size: clamp(30px, 12vw, 60px);
        color: $white;
        text-transform: uppercase;
        margin-bottom: $margin-md;
      }

      img {
        display: block;
        width: calc(100% - 50px);
        max-width: 400px;
        margin: 0 auto $margin-lg auto;
        @media screen and (min-width: $xs-screen) {
          width: 100%;
        }
      }

    }

    .form {
      flex: 1;
      width: 100%;
      max-width: 440px;
      background-color: $white;
      padding: $margin-lg;
      border-radius: $radius $radius 0 0;
      @media screen and (min-width: $xs-screen) {
        flex: inherit;
        border-radius: $radius;
      }
    }

    @media screen and (min-width: $md-screen) {
      flex-direction: row;

      >div:first-child {
        margin-right: $margin-xl;
      }

      .form {
        width: 500px;
        margin-left: $margin-xl;
      }

    }

    @media screen and (min-width: $lg-screen) {
      flex-direction: row;

      >div:first-child {
        margin-right: $margin-xl;
      }

      .form {
        margin-left: $margin-xl;
      }

    }
    
  }
}