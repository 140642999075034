@import "../../styles/_variables.scss";

.inputSearch {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    fill: $secondary-color;
  }

  input {
    font-family: 'MuseoSans';
    font-size: $regular;
    padding: 0;
    border: none;
    background-color: transparent;
    margin-left: $margin-xs;
    &::placeholder {
      font-family: 'MuseoSans';
      font-size: $regular;
      opacity: 1;
      color: $text-color;
    }
  }
}