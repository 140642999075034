@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.editFormation {
  margin-bottom: 90px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .backward {
      @include backward();
    }

    .containerActions {
      text-align: right;
    }

  }
  .content {
    @include content();
    
    
    >.containerActions {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      width: calc(100% - 430px);

      @media screen and (min-width: $md-screen) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .delete {
        color: $warn;
        margin-top: $margin-md;
        @media screen and (min-width: $md-screen) {
          margin-top: 0;
        }

        svg {
          position: relative;
          top: 2px;
          margin-right: $margin-xs;
        }
      }

      .error {
        width: 100%;
      }
    }
  }
}