@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.modalCollaborator {
  h1 {
    text-align: center;
    margin-bottom: $margin-lg;
  }

}

.account {

  button {
    display: flex;
    align-items: center;
    span + span {
      margin-left: $margin-sm;
    }
  }

  .header {
    display: flex;
    align-items: center;
    max-width: 1440px;
    width: 100%;
    padding: $margin-sm $margin-sm 0 $margin-sm;
    margin: 0 auto;
  
    @media screen and (min-width: $xs-screen) {
      padding: $margin-md $margin-md 0 $margin-md;
    }
  
    @media screen and (min-width: $sm-screen) {
      padding: $margin-lg $margin-lg 0 $margin-lg;
    }

    .loader {
      position: relative;
      top: 3px;
      margin-left: $margin-sm;
    }

    h1, p {
      margin-bottom: 0;
    }

    p {
      font-family: 'Museo-Light';
      background-color: $white;
      border-radius: 3px;
      padding: 5px;
      margin-left: $margin-sm;
    }

    button {
      margin-left: auto;
    }

  }

  >.content {
    @include content();
    gap: $margin-md;
    
    .headerBlock {
      display: flex;
      border-bottom: solid 1px $grey;
      margin-bottom: $margin-md;
      h2 {
        font-family: 'MuseoSans-Bold';
        margin-bottom: $margin-md;
        color: $text-color;
      }
    }

    .row {
      grid-gap: $margin-md;
      max-width: 700px;
  
      @media screen and (min-width: $sm-screen) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
  
      .containerField {
        @include containerField();
      }
    
    }

    .logout {
      color: $secondary-color;
      svg {
        position: relative;
        top: 2px;
      }
    }

  }

}