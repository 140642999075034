@import "../../../styles/_variables.scss";

.signin-form {
  .form {
    margin-bottom: $margin-xs;
    
    .containerField {
      margin: 0 0 15px 0;
      &:nth-child(2) {
        margin-bottom: 0;
      }
    }
    .message-error {
      color: $warn;
      font-size: $regular;
    }
  }

  .forgotPassword {
    text-align: right;
    margin-bottom: $margin-lg;

    a {
      font-family: "MuseoSans";
      text-decoration: underline;
      font-size: $small;
    }

  }

  .container-link {
    margin-bottom: $margin-md;
    p {
      font-size: $regular;
      color: $text-color;
      a {
        font-size: $regular;
        text-decoration: none;
        color: $primary-color;
      }
    }
  }

  .containerSubmit {

    .containerBtn {
      display: flex;
      align-items: center;
      > a {
        margin-top: 0;
        margin-left: $margin-sm;
      }
    }

    >a {
      display: block;
      position: relative;
      color: $text-color;
      margin-top: $margin-sm;
    }

    button {
      width: 100%;
    }

  }
}
