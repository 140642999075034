@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.rdw-editor-main {

  h1 , h1>div,
  h1 , h1>div,
  h3 , h3>div,
  h4 , h4>div,
  h5 , h5>div,
  h6 , h6>div{
    margin: 0 0 $margin-xs 0!important;
  }

  h1>div,
  h2>div,
  h3>div,
  h4>div,
  h5>div,
  h6>div{
    display: inline-block;
  }

  p,
  a,
  li {
    font-size: 12px;
  }

  li {
    margin: 3px 0;
    .public-DraftStyleDefault-block {
      margin: 0;
    }
  }

  

}

.DraftEditor-editorContainer {
  z-index: inherit;
}

.public-DraftStyleDefault-ltr {
  text-align: inherit;
  margin-top: 0;
}

.rdw-list-dropdown,
.rdw-text-align-dropdown {
  z-index: inherit;
}


.rdw-link-modal {
  width: 280px;
  left: -200px;
  border-radius: 5px;
  border: solid 1px #BCC2C5;
  height: auto;
  
  .rdw-link-modal-label {
    @include label;
    font-family: "Raleway-Bold";
    color: $text-color;
    margin-bottom: 5px;
  }

  .rdw-link-modal-input {
    height: 30px;
    margin: 2px 0px 10px 0px;
    border: solid 1px #BCC2C5;
  }
  .rdw-link-modal-target-option {
    display: none;
  }

}

.rdw-image-imagewrapper img {
  object-fit: contain;
}

.rdw-image-modal {
  .rdw-image-modal-header {
    margin: 0;
  }
  .rdw-image-modal-header .rdw-image-modal-header-option {
    font-family: "Raleway-SemiBold", sans-serif;
    width: auto;
    span {
      display: none;
    }
  }

  .rdw-image-modal-url-input {
    width: 100%;
    margin-bottom: 5px;
  }

  .rdw-image-modal-size {
    margin-bottom: 0;
    input {
      padding: 0 5px;
      margin-bottom: 5px;
    }
  }

  .rdw-image-mandatory-sign {
    display: none;
  }

}

.rdw-link-modal .rdw-link-modal-input,
.rdw-image-modal input {
  height: 30px;
  margin: 2px 0px 10px 0px;
  border: solid 1px #BCC2C5;
}

.rdw-link-modal .rdw-link-modal-label,
.rdw-image-modal .rdw-image-modal-header-option{
  @include label;
  font-family: "Raleway-Bold";
  color: $text-color;
  margin-bottom: 5px;
}

.rdw-image-modal .rdw-image-modal-btn,
.rdw-link-modal .rdw-link-modal-btn {
  font-family: "Raleway", sans-serif;
  font-size: 13px;
  background-color: #5BC284;
  padding: 0px;
  color: white;
  border: solid 1px #5BC284;
  border-radius: 5px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

}

.rdw-option-wrapper {
  background-color: #FAFAFA;
  border: none;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  height: 22px;
  cursor: pointer;
  &:hover {
    background:  $border-grey;
  }
  &.rdw-option-active {
    background:  $border-grey;
  }
}

.rdw-dropdown-wrapper {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}