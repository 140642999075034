@import '../../../styles/variables';
@import '../../../styles/mixins';

.form {

  h1 {
    margin-bottom: $margin-md;
    padding-bottom: $margin-md;
    border-bottom: solid 1px $grey;
  }

  .titleCreate {
    margin-top: $margin-md;
  }

  section {
    margin-bottom: $margin-md;
    max-width: 700px;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $margin-md;

    @media screen and (min-width: $sm-screen) {
      grid-template-columns: 1fr 1fr;
    }
  
    .containerField {
      @include containerField();
    }
  
  }

  .mainInfo {
    display: flex;
    margin-top: $margin-md;
    .picture {
      width: 120px;
      height: 120px;
    }
    .picture+div {
      margin-left: $margin-md;
      padding: $margin-xs 0 $margin-md 0;

      h2 {
        font-family: 'Museo-Bold';
        color: $secondary-color;
        margin-bottom: $margin-xs;
      }

      p {
        margin-bottom: $margin-xs;
        &.email {
          font-family: 'Museo-Bold';
          margin-bottom: $margin-sm;
        }
      }
      
    }
  }

}
