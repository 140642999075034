@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.formations {
  >.content {
    @include content();

    .header {
      margin-bottom: $margin-md;

      h1 {
        margin-bottom: $margin-sm;
      }

      .filters {
        display: grid;
        grid-template-columns: repeat(2, 1fr);


        @media screen and (min-width: 800px) {
          display: flex;
          grid-template-columns: inherit;
          grid-template-rows: inherit;
        }

        .containerButtonFilter { 
          grid-area: 1 / 1 / 2 / 2;
          margin-bottom: $margin-xs;
          @media screen and (min-width: 800px) {
            grid-area: inherit;
            margin-bottom: 0;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: inherit;
            .icon {
              position: relative;
              top:2px;
              color: $secondary-color;
              margin-left: 5px;
            }
          }

        }

        .search { 
          display: flex;
          justify-content: flex-end;
          grid-area: 1 / 2 / 2 / 3;
          margin-bottom: $margin-xs;
          @media screen and (min-width: 800px) {
            grid-area: inherit;
            margin-bottom: 0;
          }
        }
        

        .selectFilters {
          display: grid;
          grid-template-columns: 1fr;
          grid-area: 2 / 1 / 3 / 3;
          max-width: 420px;

          @media screen and (min-width: $xs-screen) {
            grid-template-columns: repeat(2, 1fr);
          }

          @media screen and (min-width: 800px) {
            display: flex;
            grid-area: inherit;
            flex-direction: row;
            max-width: inherit;
          }

          
          // flex: 1;
          // width: 100%;
          // min-width: 100%;
          // @media screen and (min-width: 800px) {
          //   flex: inherit;
          //   width: inherit;
          //   min-width: inherit;
          // }
        }
        .field {
          margin-right: $margin-md;
          @media screen and (min-width: 800px) {
            margin-right: $margin-lg;  
          }
        }
      }
  
    }

    .events {
      display: grid;
      grid-template-columns: 1fr;
      gap: $margin-md;
      margin-bottom: $margin-md;

      @media screen and (min-width: 800px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (min-width: $lg-screen) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      .event {
        border: solid 1px $white;
        border-radius: $radius;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        height: 163px;
        background-color: $white;
        &:hover {
          border: solid 1px $secondary-color;
          background-color: $white;
        }
  
        .content {
          display: flex;
          height: 100%;
          justify-content: space-between;
  
          >div {
            position: relative;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            padding: $margin-md;
  
            .type {
              font-family: "Museo-Bold";
              font-size: $small;
              margin-bottom: $margin-md;
              padding-left: $margin-xs;
              border-left: solid 1px $secondary-color;
            }
  
            .name {
              font-family: "Museo-Bold";
              margin-bottom: $margin-xs;
            }

            .region {
              font-family: "Museo-Bold";
              font-size: $small;
              color: $secondary-color;
            }
  
            .day {
              font-size: $small;
              margin-bottom: 0;
            }
  
            .speakers {
              p {
                display: inline-block;
                color: $secondary-color;
                font-size: $small;
                margin: 0 $margin-xs 0 0;
              }
            }
          }
          
          img {
            object-fit: cover;
            width: 163px;
            height: 163px;
          }
  
        }
  
      }
    }

  }
}