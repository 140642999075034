@import "../../styles/_variables.scss";

.overlay-modal{
  position: fixed;
  z-index: 3000;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba(26, 27, 66, .6);
}

.modal {
  position: fixed;
  z-index: 3001;
  top:120px;
  left:50%;
  transform: translate(-50%,0);
  width: 310px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  padding: $margin-sm;

  @media screen and (min-width: $sm-screen) {
    top:20px;
  }

  p{
    margin-top: 0;
  }

  .container-btn{
    display: flex;
    justify-content: center;

    button {
      font-size: $regular;
      padding: $margin-sm $margin-md;
    }

    button:first-child{
      margin-right: 5px;
    }
    button:last-child{
      margin-left: 5px;
    }
  }
}
