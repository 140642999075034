@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.formations {
  .content {
    @include content();

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin-md;

      h1 {
        margin-bottom: $margin-sm;
      }
  
      .backward {
        @include backward();
      }

      .filters {
        // display: flex;
        // align-items: center;
    
        @media screen and (min-width: $sm-screen) {
          display: flex;
          align-items: center;
        }
    
        .field {
          margin-right: $margin-md;
          margin-bottom: $margin-md;
          @media screen and (min-width: $sm-screen) {
            margin-bottom: 0;
          }
          label {
            margin-bottom: 0;
          }
        }
    
        .searchField {
          display: inline-block;
          margin-top: 3px;
          padding: 4px 0;
        }
      }
  
    }

  }
}