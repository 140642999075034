@import './_mixins.scss';
@import './_variables.scss';

body {
  margin: 0;
  font-family: "Museo";
  font-size: $regular;
  background-color: $bg-color;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: sans-serif;
}

html, body, #root, .App {
  min-height: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  @include h1();
  margin: 0 0 15px 0;
}

label,
p {
  font-size: $regular;
  color: $text-color;
  margin: 0 0 $margin-sm 0;
  &.small {
    font-size: $small;
  }
}

p {
  font-family: 'MuseoSans';
}

label {
  font-family: 'Museo-Bold';
}

a, li {
  font-size: $regular;
  font-family: 'MuseoSans';
  color: $text-color;
}

button {
  font-family: 'Museo-Bold';
  text-transform: uppercase;
  color: $white;
  font-size: $medium;
  border: none;
  background-color: $secondary-color;
  outline: inherit;
  box-shadow: none;
  padding: 15px $margin-lg;
  border-radius: $radius;
  
  .icon {
    vertical-align: middle;
  }


  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    background-color: $grey;
  }

  &.secondary {
    border: solid 1px $secondary-color;
    background-color: $white;
    color: $text-color;
  }

  &.invisible {
    padding: 0;
    border: none;
    background-color: transparent;
    color: $text-color;
    &:disabled {
      color: $text-color-light;
      opacity: 0.5;
    }
  }

}

.Toastify {
  .Toastify__toast--error {
    background-color: red;
    .Toastify--animate-icon svg {
      fill: white;
    }
  }
  // div[class$='__toast--error'] {
  //   background-color: red;
  // }
}

