@import "../../styles/_variables.scss";

.mobile {
  @media screen and (min-width: 850px) {
    display: none;
  }
}

.desktop {
  @media screen and (max-width: 850px) {
    display: none;
  }
}

.orderValidated {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: $margin-lg;
    &.error {
      color: #EC5E52;
    }
  }
}

.orders {
  .order {
    margin-bottom: $margin-md;
    cursor: pointer;

    @media screen and (min-width: 850px) {
      padding-bottom: $margin-md;
      cursor: default;

      &:not(:last-child) {
        border-bottom: solid 1px $grey;
      }
    }

    .orderHeader {
      padding: 0;
      background-color: $white;
      padding: $margin-md 0;
      border-radius: $radius;

      @media screen and (min-width: 850px) {
        padding: 0;
        display: flex;
        align-items: center;
      }

      .provider {
        margin: 0 $margin-md $margin-sm 0;
        @media screen and (min-width: 850px) {
          margin: 0 $margin-md 0 0;
        }
        p:first-child {
          font-family: 'Museo-Bold';
          color: $text-color;
          margin-bottom: 0;
        }
        p:last-child {
          display: block;
          font-family: 'Museo-light';
          span {
            display: inline-block;
            background-color: $bg-color;
            padding: $margin-xs;
            margin-left: $margin-xs;
            text-align: center;
            min-width: 30px;
            border-radius: 3px;
          }
          @media screen and (min-width: 850px) {
            display: none;
          }
        }
      }

      .detail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (min-width: 850px) {
          justify-content: flex-start;
        }
        p {
          font-family: 'Museo-light';
          margin: 0;
          color: $text-color-light;
          &:first-child {
            margin: 0 $margin-md 0 0;
            span {
              font-family: 'Museo-Bold';
            }
          }
        }

        .status {
          span {
            position: relative;
            display: inline-block;
            border: solid 1px var(--tonic);
            color: var(--tonic);
            padding: $margin-xs $margin-sm;
            border-radius: 4px;
            &:after {
              content: '';
              position: absolute;
              display: block;
              inset: 0;
              background-color: var(--tonic);
              opacity: 0.15;
            }
          }
        }

      }

      .actions {
        display: none;
        align-items: center;
        margin-left: auto;
        @media screen and (min-width: 850px) {
          background-color: transparent;
          display: flex;
        }

        .validate {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: -5px 0;
          height: 50px;
          padding-left: 15px;
          svg {
            position: relative;
            top: 2px;
          }
        }

        :last-child {
          margin-left: $margin-sm;
        }
      }

      @media screen and (min-width: 850px) {
        background-color: transparent;
        height: 40px;
      }

    }

    .products {
      display: none;
      margin-top: $margin-sm;
      @media screen and (min-width: 850px) {
        display: block;
      }
    }

  }
}