@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $margin-md;

  h1 {
    margin-bottom: $margin-sm;
  }

  .filters {
    // display: flex;
    // align-items: center;

    @media screen and (min-width: $sm-screen) {
      display: flex;
      align-items: center;
    }

    .field {
      margin-right: $margin-md;
      label {
        margin-bottom: 0;
      }
    }

    .searchField {
      display: inline-block;
      margin-top: 3px;
      padding: 4px 0;
    }
  }

}

.containerList {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background-color: $white;
  border-radius: $radius;
  overflow: hidden;

  .list {
    flex: 1;
    overflow-y: auto;
  }

  .footer {
    padding: $margin-md $margin-lg $margin-md $margin-lg;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (min-width: 900px) {
      padding: $margin-md $margin-lg $margin-md $margin-lg;
    }
  }
}