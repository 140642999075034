@import '../../styles/variables';
@import '../../styles/mixins';


.success {
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  &.hide {
    pointer-events: none;
  }

  &:not(.hide) {
    animation-delay: 0.6s;
    animation-name: fadeIn;
    animation-duration: .4s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

}

.scan {
  position: relative;
  width: 100%;
  opacity: 0;
  animation-delay: 0.9s;
  animation-name: fadeIn;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &.success {
    opacity: 1;
    pointer-events: none;
    animation-delay: 0s;
    animation-name: fadeOut;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  button {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 100px;
    white-space: nowrap;
    transform: translate(-50%, 0);

    @media screen and (min-width: $md-screen) {
      bottom: 60px;
    }

  }

  .error {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 60px;
    white-space: nowrap;
    transform: translate(-50%, 0);
    background-color: $warn;
    color: $white;
    text-transform: none;
    font-family: 'MuseoSans';
    border-radius: 0px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

}