@import "../../styles/_variables.scss";

.companyHeader {

  .containerName {
    display: flex;
    margin: $margin-sm auto;
    min-height: 45px;

    .name {
      display: flex;
      align-items: center;
      width: 100%;
  
      h1, p {
        margin-bottom: 0;
      }
  
      p {
        font-family: 'Museo-Light';
        background-color: $white;
        border-radius: 3px;
        padding: 5px;
        margin-left: $margin-sm;
      }
    }

    .actions {
      margin-left: auto;
    }

  }


  .details {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: $margin-md;

    @media screen and (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
      margin-bottom: $margin-sm;
    }

    @media screen and (min-width: $sm-screen) {
      display: flex;
    }

    >p {
      margin-right: $margin-md;
      >span {
        font-family: 'Museo-Bold';
      }
    }
  }

  nav {
    display: flex;
    padding: 0;
    margin-bottom: $margin-md;
    border-bottom: solid 1px $grey;
    @media screen and (min-width: $sm-screen) {
      border-bottom: none;
    }  

    >div {
      flex: 1;
      @media screen and (min-width: $sm-screen) {
        flex: inherit;
      }  
    }

    @media screen and (min-width: 1180px) {
      padding: 0 $margin-md;
      margin-bottom: 0;
    }  

    a {
      position: relative;
      display: block;
      padding: 0 $margin-sm $margin-sm $margin-sm;
      text-align: center;

      @media screen and (min-width: 1180px) {
        padding: 0 $margin-sm 15px $margin-sm;
      }  

      &.active {
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          height: 4px;
          border-radius: 2px 2px 0 0;
          background-color: $secondary-color;
        }
      }
      &:not(.active) {
        color: $grey;
      }
    }
  }

}