@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $margin-md;

  h1 {
    margin-bottom: $margin-sm;
  }

  .backward {
    @include backward();
  }

  .searchField {
    display: inline-block;
  }

  .actions {
    display: flex;
    button:first-child,
    button:nth-child(2) {
      margin-right: $margin-sm;
    }
  }

}

.containerList {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background-color: $white;
  border-radius: $radius;
  overflow: hidden;

  .list {
    flex: 1;
    overflow-y: auto;
  }

  .footer {
    padding: $margin-sm $margin-lg $margin-md $margin-lg;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

}