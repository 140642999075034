@import "../../styles/_variables.scss";

.navbar {
  position: relative;
  z-index: 2;
  display: flex;
  background-color: $white;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  height: 90px;

  .logo {
    position: absolute;
    top: 50%;
    left: $margin-md;
    transform: translateY(-50%);
    img {
      height: 60px;
    }
  }

  nav ul {
    margin: 0;
    padding: 0;
    li {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      height: 90px;
      padding: 0 $margin-md;

      @media screen and (min-width: $lg-screen) {
        padding: 0 $margin-lg;
      }

      a {
        display: block;
        letter-spacing: 1%;
        &.active {
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            height: 3px;
            background-color: $secondary-color;
          }
        }
        
      }

      &.mobile {
        @media screen and (min-width: 1050px) {
          display: none;
        }
      }

    }
  }

  .actions {
    display: flex;
    position: absolute;
    top: 50%;
    right: $margin-md;
    transform: translateY(-50%);

    .burger {
      display: none;
    }

    button {
      color: $text-color;
    }

    .profile {
      .picture {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        width: 45px;
        border-radius: 5px;
        background-color: $secondary-color;
        margin-right: $margin-xs;
        cursor: pointer;
        p {
          margin: 0;
          color: $white;
          text-transform: uppercase;
        }
      }
    }

  }

  .logoutReception {
    display: flex;
    position: absolute;
    top: 50%;
    right: $margin-md;
    transform: translateY(-50%);
    a {
      color: $secondary-color;
    }
  }


  @media screen and (max-width: 1050px) {
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;

    .actions {
      .profile, button:not(.burger) {
        display: none;
      }

      .burger {
        display: block;
        padding: 0 $margin-md;
      }

    }

    &:not(.open) {
      nav {
        display: none;
      }
    }

    &.open {
      nav {
        position: fixed;
        top: 83px;
        bottom: 0;
        display: block;
        background-color: $white;
        ul {
          display: block;
          li {
            display: block;
            height: auto;
            padding: $margin-sm;
            margin-bottom: $margin-sm;
          }
        }
      }
    }


    >div:first-child {
      display: flex;
      justify-content: space-between;
      flex: 1;
      width: 100%;
      padding: $margin-sm;
    }

    nav {
      flex: 1;
      width: 100%;

      li {
        position: relative;
        padding-left: $margin-md !important;
        a.active {
          color: $secondary-color;
          &:after {
            top: 0;
            bottom: 0;
            left: 0;
            height: 100% !important;
            width: 8px !important;
          }
        }
  
      }
      .logout {
        a {
          cursor: pointer;
          color: $secondary-color;
          svg {
            position: relative;
            top: 4px;
            margin-right: $margin-sm;
          }
        }
      }

    }

    .actions, .logo {
      position: relative;
      transform: translateY(0);
    }
    
    .actions {
      top: 0;
      left: 0;
    }

    .logo {
      top: 0;
      left: 0;
      right: 0;
    }

  }

}