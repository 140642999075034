@import '../../../styles/_variables.scss';

.productsTable {
  padding: 0 $margin-lg 0 $margin-lg;
  background-color: $white;
  border-radius: $radius;
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    border-spacing: 0;

    thead {
      position: relative;
      position: sticky;
      top: 0;
      background-color: white;
      &:after {
        content: '';
        position: absolute;
        right: calc(-1 * $margin-lg);
        left: calc(-1 * $margin-lg);
        bottom: 0;
        border-bottom: solid 1px $border-grey;
      }


      th {
        position: relative;
        padding: $margin-md 0 $margin-md 0;
        font-family: 'Museo-light';
        color: $text-color-light;
        text-align: left;
        cursor: pointer;

        @media screen and (min-width: 1180px) {
          &:nth-child(2) {
            width: 200px;
          }
  
  
          &:nth-child(3) {
            width: 200px;
          }
        }

        &:last-child {
          width: 90px;
        }

        .sortIcons {
          position: relative;
          top: -6px;
          display: inline-flex;
          flex-direction: column;
          // opacity: 0.3;
          margin-left: $margin-xs;
          height: 20px;

          span:not(.active) {
            opacity: 0.3;
          }

          >span:first-child {
            transform: translateY(5px);
          }
          >span:nth-child(2) {
            transform: translateY(-5px);
          }
        }

      }
    }

    tbody {
      tr {
        cursor: pointer;
        &:not(:last-child) td {
          border-bottom: solid 1px $border-grey;
        }
      }
      
      td {
        padding: $margin-md $margin-xs $margin-md 0;
    
        p {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.discount {
          p {
            margin: calc(-1 * $margin-xs);
          }
          
          .discount {
            display: inline-block;
            font-family: 'MuseoSans-Bold';
            background-color: rgba(#EC5E52, 0.5);
            padding: $margin-xs;
            border-radius: 3px;
          }
        }

      }
    }

  }
}

.listTablet {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $margin-md;
  margin-bottom: $margin-md;

  @media screen and (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  li {
    list-style: none;
    .product {
      position: relative;
      background-color: $white;
      padding: $margin-md 65px $margin-md $margin-md;
      border-radius: $radius;


      .discount {
        position: absolute;
        top: $margin-sm;
        right: $margin-sm;
        display: inline-block;
        font-family: 'MuseoSans-Bold';
        background-color: rgba(#EC5E52, 0.5);
        padding: $margin-xs;
        border-radius: 3px;
      }


      .name {
        font-family: 'Museo-Bold';
      }
      p {
        font-family: 'Museo-light';
        margin: 0 0 $margin-xs 0;
      }

      .details {
        width: 100%;
        margin-top: $margin-xs;

        p {
          color: $text-color-light;
          margin-bottom: $margin-xs;
          span {
            font-family: 'Museo-Bold';
            color: $text-color;
          }
        }
      }


    }
  }
}