
@import './_variables.scss';

@mixin h1 {
  font-size: $h1;
  font-family: 'Museo-Bold';
  color: $secondary-color;
}

@mixin content {
  max-width: 1440px;
  width: 100%;
  padding: $margin-sm;
  margin: 0 auto;

  @media screen and (min-width: $xs-screen) {
    padding: $margin-md;
  }

  @media screen and (min-width: $sm-screen) {
    padding: $margin-lg;
  }
}

@mixin content-large {
  max-width: 1700px;
  width: 100%;
  margin: 0 auto;
}

@mixin containerField {
  margin-bottom: $margin-md;
}

@mixin backward {
  font-family: "MuseoSans-Bold";
  font-size: $small;
  text-transform: uppercase;
  svg {
    position: relative;
    top: 1px;
    vertical-align: baseline;
    margin-right: $margin-sm;
    font-size: 20px;
    vertical-align: bottom;
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($black, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $black;
  }
}

@mixin remove-highlight() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

@mixin list() {
  margin: 0 0 $margin-xl 0;
  .item {
    margin: 0 0 $margin-sm 0;
    border-bottom: solid 1px $grey;
    p {
      margin: 0 0 $margin-sm 0;
    }
  }
}