@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.orders {
  padding: $margin-md;
  .header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $margin-sm;

    >div:first-child {
      display: flex;
      align-items: flex-end;
      color: $secondary-color;
      margin-bottom: $margin-sm;
      h1 {
        margin-bottom: 0;
        margin-left: $margin-sm;
      }
      
      p {
        font-family: 'MuseoSans-Bold';
        margin: 0 0 2px $margin-sm;
      }
      // p {
      //   line-height: 29px;
      //   height: 29px;
      //   vertical-align: bottom;
      // }

    }

    .filters {
      display: flex;

      @media screen and (max-width: $xs-screen) {
        flex: 1;
        min-width: 100%;
      }

      .field {
        margin-left: $margin-sm;
      }
    }
  }
}