@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

// Modal select speaker

.selectSpeakers {
  .listSpeakers {
    max-height: 500px;
    overflow-y: auto;

    .speaker {
      padding: $margin-xs $margin-sm;
      background-color: $bg-color;
      margin-bottom: $margin-sm;
      cursor: pointer;
      border-radius: 3px;
      p {
        margin-bottom: 0;
      }
      .name {
        font-family: 'MuseoSans-Bold';
        font-size: $regular;
      }

      .function {
        font-size: $small;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  @media screen and (min-width: $md-screen) {
    flex-direction: row;
  }

  form {
    width: 100%;

    @media screen and (min-width: $md-screen) {
      width: calc(100% - 430px);
    }

    .selectFields {
      display: grid;
      grid-template-columns: 1fr;
      gap: $margin-sm;
      @media screen and (min-width: $sm-screen) {
        grid-template-columns: 1fr 1fr;
      }
    }

    .containerField {
      @include containerField();

      label {
        white-space: wrap;
      } 
      @media screen and (min-width: $sm-screen) {
        label {
          white-space: nowrap;
        } 
      }
  
      &.name {
        input {
          font-family: 'MuseoSans';
          font-size: $h1;
          background-color: transparent;
          border-top: none;
          border-left: none;
          border-right: none;
          border-radius: 0;
          padding-left: 0;
          padding-right: 0;
          &::placeholder {
            font-family: 'MuseoSans';
            font-size: $h1;
          }
        }
      }

      &.seats {
        >div {
          justify-content: flex-start;
        }
        input {
          width: 100px;
        }
      }
  
    }

    .speakers {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: $margin-sm;
      padding: auto;
      margin: $margin-md 0;

      @media screen and (min-width: $sm-screen) {
        grid-template-columns: 1fr 1fr;
      }  

      @media screen and (min-width: $md-screen) {
        grid-template-columns: 1fr;
      } 

      @media screen and (min-width: 1100px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (min-width: 1440px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      li {
        width: 100%;
        list-style: none;

        .speaker {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-color: $white;
          border-radius: $radius;
          padding: 0 $margin-md;
          height: 85px;
          width: 100%;

          .close {
            position: absolute;
            top: 2px;
            right: 2px;
            display: none;
            color: $warn;
            cursor: pointer;
          }

          &:hover .close {
            display: block;
          }

          .picture {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 46px;
            height: 46px;
            min-width: 46px;
            min-height: 46px;
            border-radius: 50%;
            background-color: $secondary-color;
            margin-right: $margin-sm;
            p {
              font-family: 'MuseoSans-Bold';
              font-size: $regular;
              color: $white;
            }
          }

          p {
            margin: 0;
          }

          .name {
            font-family: 'MuseoSans-Bold';
            font-size: $regular;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 200px;
          }

          .function {
            font-size: $small;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 200px;
          }

        }

        .addSpeakerButton {
          border: dashed 2px $secondary-color;
          padding: 0 $margin-md;
          height: 85px;
          color: $secondary-color;
          width: 100%;
        }

      }
    }

    .timeslots {
      >label {
        display: inline-block;
        margin-bottom: $margin-md;
      }

      &:not(.conference) .dayHeader {
        .containerField {
          margin: 0;
        }
      }


      .day {
        border-bottom: solid 1px $border-grey;
        padding-bottom: $margin-md;
        margin-bottom: $margin-md;

        &:last-child {
          border: none;
        }

      }
      .day .dayHeader {
        display: flex;
        justify-content: space-between;

        .checkbox {
          margin-right: auto;
        }

        // p {
        //   margin: 0;
        // }

        label,
        button {
          @media screen and (max-width: $sm-screen) {
            font-size: $small;
          }
        }

        button:not(:disabled) { 
          color: $secondary-color;
        }
      }
    }
  }

  .inputPicture {
    width: 340px;
    height: 340px;
    margin-bottom: 60px;
    margin-top: $margin-md;

    @media screen and (min-width: 490px) {
      width: 400px;
      height: 400px;
    }

    @media screen and (min-width: $sm-screen) {
      margin-top: 0px;
    }
    @media screen and (min-width: $md-screen) {
      width: 430px;
      height: 400px;
      flex: 0.5;
      margin-top: 50px;
      margin-bottom: 0;
      padding-left: $margin-lg;
    }
  }

}
